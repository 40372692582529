<template>
  <Layout class="main-container">
    <!--    搜索块-->
    <Form
      ref="SearchForm"
      class="search-form-container"
      :model="params"
      label-position="left"
      :label-width="80"
    >
      <div class="search-form-container__content">
        <Row :gutter="16">
          <Col span="6">
            <FormItem label="订单编号:" prop="OrderCode">
              <Input
                placeholder="请输入订单编号"
                v-model="params.OrderCode"
                clearable
              />
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="内部流水号:" prop="PayID">
              <Input
                placeholder="请输入内部流水号"
                v-model="params.PayID"
                clearable
              />
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="外部流水号:" prop="PaySerial">
              <Input
                placeholder="请输入外部流水号"
                v-model="params.PaySerial"
                clearable
              />
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="支付类型:" prop="Type">
              <Select clearable v-model="params.Type" placeholder="支付类型">
                <Option
                  v-for="item in PayLogTypeEnum"
                  :value="item.ID"
                  :key="item.ID"
                >
                  {{ item.CN }}
                </Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="16">
          <Col span="6">
            <FormItem label="支付方式:" prop="PayType">
              <Select clearable v-model="params.PayType" placeholder="支付方式">
                <Option
                  v-for="item in PayLogPayTypeEnum"
                  :value="item.ID"
                  :key="item.ID"
                >
                  {{ item.CN }}
                </Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="6">
            <FormItem label="支付状态:" prop="PayStatus">
              <Select
                clearable
                v-model="params.PayStatus"
                placeholder="支付状态"
              >
                <Option
                  v-for="item in PayStatusEnum"
                  :value="item.ID"
                  :key="item.ID"
                >
                  {{ item.CN }}
                </Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
      </div>
      <Row type="flex" justify="space-between" align="bottom" class="mt5">
        <Col span="12" style="text-align: left">
          <Button
            type="success"
            icon="md-add-circle"
            v-if="$canAction(['PayLog_Edi'])"
            @click="handleOpenAdd"
          >
            新增
          </Button>
        </Col>
        <Col span="12" style="text-align: right">
          <span
            class="search-form-container__arrow_btn"
            @click="$handleHiddenSearch($el)"
          >
            收起
            <Icon type="ios-arrow-up" />
          </span>
          <Button
            class="ml2 search-form-container__button"
            type="primary"
            ghost
            icon="ios-search"
            @click="handleSearch"
          >
            搜索
          </Button>
          <Button
            class="ml2 search-form-container__button"
            @click="handleReset"
          >
            重置
          </Button>
        </Col>
      </Row>
    </Form>
    <!--    table-->
    <Table
      class="mt10 main-table"
      :loading="tableLoading"
      :height="tableHeight"
      ref="dataTable"
      :columns="columns"
      :data="resObj.DataList"
      highlight-row
      size="small"
      @on-sort-change="sortChange"
      stripe
    >
      <template slot="Image" slot-scope="{ row }">
        <Tooltip placement="right">
          <Avatar
            :src="$setImagePrefix(row.PayPic, '')"
            style="min-width: 31px; height: 31px; margin-top: 3px"
          />
          <div slot="content" style="height: 230px">
            <img
              :src="$setImagePrefix(row.PayPic, '')"
              height="230"
              style="min-width: 230px; height: 230px; margin-top: 3px"
            />
          </div>
        </Tooltip>
      </template>

      <template slot="Action" slot-scope="{ row }">
        <Button
          class="ml2"
          size="small"
          type="primary"
          v-if="$canAction(['PayLog_Edit'])"
          @click="editDrawer(row)"
        >
          编辑
        </Button>
      </template>

      <template slot="OrderCode" slot-scope="{ row }">
        <Span
          style="color: green; cursor: pointer"
          @click="openOrderDrawer(row, true)"
        >
          {{ row.OrderCode }}
        </Span>
      </template>
    </Table>
    <!--    分页-->
    <Row class="mt10">
      <Col span="24" class="flex-end">
        <Page
          :page-size-opts="[40, 60, 80, 100]"
          :page-size="40"
          :total="resObj.TotalCount"
          :current="resObj.CurrentPage"
          @on-page-size-change="handleChangePageSize"
          @on-change="handleChangePage"
          size="small"
          show-elevator
          show-sizer
          show-total
        />
      </Col>
    </Row>
    <Modal v-model="EditView" width="360" class-name="i-modal">
      <p slot="header" style="color: green; text-align: center">
        <Icon type="information-circled"></Icon>
        <span>支付状态</span>
      </p>
      <div style="text-align: center">
        <Form ref="form" class="modelViewFromCheck">
          <FormItem prop="ID" label="支付状态">
            <Select clearable v-model="Status" placeholder="支付状态">
              <Option
                v-for="item in PayStatusEnum"
                :value="item.ID"
                :key="item.ID"
              >
                {{ item.CN }}
              </Option>
            </Select>
          </FormItem>
        </Form>
      </div>
      <div slot="footer">
        <Button
          type="success"
          size="large"
          long
          @click="handleSuperBonus"
          :loading="BonusLoading"
        >
          确认
        </Button>
      </div>
    </Modal>
    <!--    订单明细信息展示抽屉-->
    <OrderDetailDrawer ref="orderDetailDrawer" />
  </Layout>
</template>

<script>
import api from "@/api";
import Layout from "@/components/layout/index.vue";
import bizEnum from "@/libs/bizEnum";
import OrderDetailDrawer from "./components/detail-drawer.vue";
export default {
  name: "PayLog",
  data() {
    return {
      EditView: false,
      RowID: 0,
      Status: 0,
      tableLoading: false,
      tableHeight: 0,
      BonusLoading: false,
      params: {
        OrderCode: "",
        PayID: "",
        PaySerial: "",
        Type: "",
        PayType: "",
        PayStatus: "",
        Page: 1,
        PageSize: 40,
        SortName: "PayDate",
        SortOrder: "Desc",
      },
      columns: [
        { title: "凭证", key: "PayPic", slot: "Image", minWidth: 80 },
        {
          title: "订单编号",
          key: "OrderCode",
          slot: "OrderCode",
          minWidth: 175,
        },
        { title: "内部流水号", key: "PayID", minWidth: 180 },
        { title: "外部流水号", key: "PaySerial", minWidth: 210 },
        {
          title: "支付金额",
          sortable: "custom",
          key: "PayMoney",
          minWidth: 90,
        },
        //{ title: "支付类型", key: "TypeCN", minWidth: 80 },
        { title: "支付方式", key: "PayTypeCN", minWidth: 80 },
        { title: "支付状态", key: "PayStatusCN", minWidth: 80 },
        {
          title: "支付时间",
          sortable: "custom",
          key: "PayDate",
          minWidth: 135,
        },
      ],
      resObj: {
        CurrentPage: 1,
        TotalCount: 0,
        TotalPage: 0,
        DataList: [],
      },
    };
  },
  computed: {
    PayLogTypeEnum() {
      return bizEnum.PayLogTypeEnum;
    },
    PayLogPayTypeEnum() {
      return bizEnum.PayTypeEnum;
    },
    PayStatusEnum() {
      return bizEnum.PayStatusEnum;
    },
  },
  created() {
    if (this.$route.meta.tableActionWidth) {
      this.columns.push({
        title: "操作",
        key: "action",
        slot: "Action",
        align: "center",
        fixed: "right",
        minWidth: this.$route.meta.tableActionWidth,
        maxWidth: this.$route.meta.tableActionWidth,
      });
    }
    this.loadPayLogList();
  },
  mounted() {
    const self = this;
    self.$nextTick(() => {
      self.tableHeight = self.$getTableHeight("main-container");
    });
    window.onresize = function () {
      self.$nextTick(() => {
        self.tableHeight = self.$getTableHeight("main-container");
      });
    };
    document.onkeydown = function (event) {
      var e = event || window.event;
      if (self.$route.name === self.$options.name && e && e.keyCode === 13) {
        // enter 键
        self.loadPayLogList();
      }
    };
    this.$bus.$on("refreshPage", () => {
      if (this.$route.name === this.$options.name) {
        this.params && (this.params = this.$options.data().params);

        this.loadPayLogList();
      }
    });
  },
  activated() {
    const self = this;
    document.addEventListener("keydown", function (event) {
      var e = event || window.event;
      if (self.$route.name === self.$options.name && e && e.keyCode === 13) {
        // enter 键
        self.loadPayLogList();
      }
    });
  },
  deactivated() {
    document.removeEventListener("keydown", function () {});
  },
  methods: {
    handleChangePageSize(pagesize) {
      this.params.Page = 1;
      this.params.PageSize = pagesize;
      this.loadPayLogList();
    },
    handleChangePage(page) {
      this.params.Page = page;
      this.loadPayLogList();
    },
    // 日期变化方法
    dateUpdate(value) {
      this.params.DateBegin = value[0];
      this.params.DateEnd = value[1];
    },
    editDrawer(row) {
      this.RowID = row.ID;
      this.Status = row.PayStatus;
      this.EditView = true;
    },
    async loadPayLogList() {
      this.tableLoading = true;
      try {
        const res = await api.GetPayLogList(this.params);
        this.resObj = res.Data;
        this.tableLoading = false;
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.tableLoading = false;
        // TODO: 记录错误日志
      }
    },
    handleSearch() {
      this.params.Page = 1;
      this.loadPayLogList();
    },
    handleReset() {
      this.$refs.SearchForm.resetFields();
      this.loadPayLogList();
    },
    // 远程排序
    sortChange(result) {
      this.params.SortName = result.key;
      this.params.SortOrder = this.params.SortOrder === "Desc" ? "Asc" : "Desc";
      this.loadPayLogList();
    },
    handleOpenAdd() {
      this.$refs.payLogAddDrawer.obj = {};
      this.$refs.payLogAddDrawer.obj.PayType = 3;
      this.$refs.payLogAddDrawer.isShow = true;
      this.$refs.payLogAddDrawer.isEdit = false;
      this.$refs.payLogAddDrawer.isAdd = true;
    },
    onUpdated() {
      this.$delayLoad(() => {
        this.loadPayLogList();
      });
    },
    async openOrderDrawer(row) {
      try {
        let res = await api.GetOrderByCode({
          code: row.OrderCode,
        });
        if (res.Data) {
          this.$refs.orderDetailDrawer.obj = res.Data;
          this.$refs.orderDetailDrawer.isShow = true;
          this.$refs.orderDetailDrawer.isEdit = false;
        }
      } catch (error) {
        this.$Notice.error({ title: "错误", desc: error });
      }
    },
    async handleOpenDelete(row) {
      const _this = this;
      _this.$Modal.confirm({
        title: "提示",
        content: "您确定要删除这条记录吗？",
        loading: true,
        onOk: function () {
          _this.Delete(row);
        },
      });
    },
    async Delete(row) {
      try {
        const res = await api.DeleteBonusReflect({
          id: row.ID,
        });
        if (res.Data) {
          row.Status = -99;
          row.StatusCN = "已删除";
          const index = this.resObj.DataList.findIndex(
            (item) => item.ID === row.ID
          );
          this.resObj.DataList.splice(index, 1, row);
          this.$Message.success("删除成功");
          this.$Modal.remove();
        } else {
          this.$Message.error(res.Msg);
          this.$Modal.remove();
        }
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.$Modal.remove();
      }
    },
    async handleSuperBonus() {
      this.BonusLoading = true;
      try {
        await api.SavePayLogStatus({
          id: this.RowID,
          status: this.Status,
        });

        const temp = this.resObj.DataList.find(
          (item) => item.ID === this.RowID
        );
        temp.PayStatus = this.Status;
        if (temp.PayStatus === 0) {
          temp.PayStatusCN = "未支付";
        }
        if (temp.PayStatus === 1) {
          temp.PayStatusCN = "支付失败";
        }
        if (temp.PayStatus === 2) {
          temp.PayStatusCN = "支付成功";
        }
        if (temp.PayStatus === 3) {
          temp.PayStatusCN = "已退款";
        }
        if (temp.PayStatus === 4) {
          temp.PayStatusCN = "部分退款";
        }
        this.BonusLoading = false;
        this.$Message.success("操作成功");
        this.EditView = false;
      } catch (err) {
        this.BonusLoading = false;
        this.$Notice.error({
          title: "错误",
          desc: err,
        });
      }
    },
    // 修改成功，更改列表数据
    handleEditSuccess(res) {
      const index = this.resObj.DataList.findIndex(
        (item) => item.ID === res.ID
      );
      this.resObj.DataList.splice(index, 1, res);
    },
  },
  components: { OrderDetailDrawer, Layout },
};
</script>
